// appwriteConfig.js

const digitalPoints = {
    endpoint: process.env.REACT_APP_BUCKET_URL,
    accessKey: process.env.REACT_APP_ACCESS_KEY_ID,
    accessSecretKey: process.env.REACT_APP_ACCESS_SECRET_KEY,
    butketName: process.env.REACT_APP_BUCKET_NAME,

}

export default digitalPoints
